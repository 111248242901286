import { LoginPage } from "templates";

const Login = () => {

  document.title = "Login | ADMIN PGK"

  return (
    <LoginPage/>
  )
}

export default Login