import { ReviewPage } from "templates";

export const Review = () => {

  document.title = "Reviews | ADMIN PGK";

  return (
    <>
      <ReviewPage/>
    </>
  );
};

export default Review;
