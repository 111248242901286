import { projectConstants } from "redux/constants"

const initState = {  
  loading: false,
  error: null,  
  message: null,
  projects: [],
  totalCount: 0,
  resultPerPage: 0
}

export const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case projectConstants.GET_PROJECTS_REQUEST:
    case projectConstants.CREATE_PROJECT_REQUEST:
    case projectConstants.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case projectConstants.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
        totalCount: action.payload.totalCount,
        resultPerPage: action.payload.resultPerPage,
        loading: false,
      }

    case projectConstants.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [action.payload.data, ...state.projects],
        message: action.payload.message,
        loading: false,
      }

    case projectConstants.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project => project.id === action.payload.data.id ? action.payload.data : project),
        message: action.payload.message,
        loading: false,
      }

    case projectConstants.GET_PROJECTS_FAILURE:
    case projectConstants.CREATE_PROJECT_FAILURE:
    case projectConstants.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case projectConstants.RESET_PLAYER:
      return{
        ...state,
        error: null,
        message: null,
        loading: false,        
      } 
      
    case projectConstants.RESET:
      return initState    

    default: return state;
  }
}