import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Loader } from "atoms";
import Dashboard from "pages/dashboard";
import Tools from "pages/tools";
import NotFound from "pages/not-found";
import Review from "pages/review";
import Project from "pages/project";

export const AppRoutes = () => {
  
  const { initLoading } = useSelector(state => state.app);  
  
  if(initLoading) return <Loader />

  return (
    <Suspense fallback={<Loader />}>      
      <Routes>
        <Route path="/" exact element={<Navigate to="/dashboard" replace />} />        
        <Route path="/dashboard" exact element={<Dashboard />} />  
        <Route path="/reviews" exact element={<Review />} />       
        <Route path="/projects" exact element={<Project />} />       
        <Route path="/tools" exact element={<Tools />} />       
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};