import { ProjectPage } from "templates";

export const Project = () => {

  document.title = "Projects | ADMIN PGK";

  return (
    <>
      <ProjectPage/>
    </>
  );
};

export default Project;
