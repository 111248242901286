import { BiNews } from 'react-icons/bi';
import { BsCalendar2EventFill } from 'react-icons/bs';
import { MdOutlineDashboard } from "react-icons/md";

export const sideNav = [
  {
    Icon: <MdOutlineDashboard/>,
    title: "Dashboard",
    to: "/dashboard",
    description: "View Admin dashboard",
  },
  {
    Icon: <BiNews/>,
    title: "Projects",
    to: "/projects",
    description: "Manage Projects",
  },
  {
    Icon: <BsCalendar2EventFill/>,
    title: "Reviews",
    to: "/reviews",
    description: "Manage Reviews",
  }  
]