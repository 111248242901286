import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { MdVisibility } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Box, Flex, InputSelect, Loader, Text } from "atoms";
import { ProjectForm } from "molecules";
import { getProjects, updateProject } from "redux/actions";
import { projectConstants } from "redux/constants";

export const ProjectPage = () => {

  const dispatch = useDispatch();
  const { projects, totalCount, resultPerPage, loading, error, message } = useSelector(state => state.project);

  const [ page, setPage ] = useState(1);
  const [ tag, setTag ] = useState(null);

  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type: projectConstants.RESET_PROJECT})
    }
    if(message){
      toast.success(message)
      dispatch({type: projectConstants.RESET_PROJECT})
    }
  }, [error, message]);

  useEffect(() => {    
    dispatch(getProjects({ page, tag }))
  }, [dispatch, page, tag])

  useEffect(() => {
    setPage(1)
  }, [tag])

  const handleUpadate = (id, active) => {
    dispatch(updateProject(id, { active: !active }))
  }

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            fontSize="2rem"
            color={params.row.active ? "success.100" : "danger.100"}
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            onClick={() => handleUpadate(params.row.id, params.row.active)}
          >
            <MdVisibility />            
          </Flex>
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1},
    { field: "description", headerName: "Description", minWidth: 300, flex: 1 },
    { field: "tag", headerName: "Tag", minWidth: 100, flex: 1 },
    { field: "location", headerName: "Location/Link", minWidth: 150, flex: 1 },        
    { field: "images", headerName: "Images", minWidth: 100, flex: 1 },        
  ]

  const rows = [];
  projects?.forEach(p => {    
    rows.push({
      id: p.id,
      title: p.title,
      description: p.description,
      tag: p.tag,
      location: p.location || p.link,
      active: p.active, 
      images: p?.projectImages?.length     
    });
  });

  return (
    <Box>
      {loading && <Loader/>}
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Projects
        </Text>
        <Flex
          alignItems="center"
          style={{ gap: "1.5rem" }}
        >
          <Box>
            <InputSelect              
              value={tag}
              setValue={setTag}
              placeholder="Select Tag"
              options={[              
                { value: "AV", label: "AV" },
                { value: "IT", label: "IT" },              
              ]}
              normal
              name="tag"
              id="tag"
            />
          </Box>           
        </Flex>                         
      </Flex>        
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box>   
      {resultPerPage < totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={resultPerPage}
          totalItemsCount={totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}            
      {totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {totalCount}
      </Text>}
      <Box
        my="2rem"
        bg="white"
        borderRadius="0.5rem"
      >
        <ProjectForm />
      </Box>  
    </Box>
  )
}