const initState = {
  loading: false,
  error: null,
  message: null,  
}

export const appReducer = (state = initState, action) => {
  switch(action.type){
    default: return state;
  }
}