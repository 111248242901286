import { combineReducers } from "redux";

import { appReducer } from "./app.reducer";
import { projectReducer } from "./project.reducer";
import { reviewReducer } from "./review.reducer";
import { userReducer } from "./user.reducer";

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  review: reviewReducer,
  project: projectReducer
})

export default rootReducer