import { useState } from "react";
import { Formik, Form } from "formik";
import { BsLink45Deg } from "react-icons/bs";
import { MdAddLocationAlt, MdOutlineDescription, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, InputSelect } from "atoms";
import { createProject } from "redux/actions";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),    
  description: Yup.string()
    .required("Description Required"),
  tag: Yup.string()
    .required("Tag Required"),  
});

export const ProjectForm = () => {  

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.project);
  const [ images, setImages ] = useState([]);

  const handleImage = (e) => {    
    if(e.target.files.length > 0){
      setImages([...images, ...e.target.files]);
    }
  }

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(images.length === 0) return toast.error("Please select an image");
    const form = new FormData();   
    form.append("title", values.title);
    form.append("description", values.description);
    form.append("tag", values.tag);
    for(let i = 0; i < images.length; i++){
      form.append("image", images[i]);
    }
    if(values.location) form.append("location", values.location);
    if(values.link) form.append("link", values.link);    
    dispatch(createProject(form));
    resetForm();
    setImages([]);
    setSubmitting(false)          
  }

  return (
    <>      
      <Box 
        maxWidth="110rem" 
        mx="auto"        
        p="4rem"        
      >               
        <Formik
          initialValues={{
            title: "",            
            description: "",
            tag: "",
            location: "",
            link: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors, values, setFieldValue }) => {
            return (
              <Form>
                <Flex                   
                  flexDirection={{xs: "column", xm: "row"}}
                  style={{ gap: "1.5rem" }}
                > 
                  <Box flex="1">
                    <Box width="100%">
                      <Input                  
                        name="title"
                        type="text"
                        placeholder="Title*"                  
                        id="title"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.title ? "danger.100" : "primary.500"}
                        touched={touched.title}
                        errors={errors.title}                        
                      />
                    </Box>                                                       
                    <Input                  
                      name="description"
                      type="text"
                      placeholder="Description*"                  
                      id="description"
                      icon={<MdOutlineDescription />}
                      iconColor={errors.description ? "danger.100" : "primary.500"}
                      touched={touched.description}
                      errors={errors.description}                    
                      textArea  
                      rows={4}                  
                    />  
                    <Flex
                      flexDirection={{xs: "column", md: "row"}}
                      style={{ gap: "1.5rem" }}
                      alignItems={{xs: "start", md: "center"}}                  
                    >
                      <Box width="100%">
                        <Input                  
                          name="location"
                          type="text"
                          placeholder="Location*"                  
                          id="location"
                          icon={<MdAddLocationAlt />}
                          iconColor={errors.location ? "danger.100" : "primary.500"}
                          touched={touched.location}
                          errors={errors.location}                                                      
                        />
                      </Box>
                      <Box width="100%">
                        <Input                  
                          name="link"
                          type="text"
                          placeholder="Link*"                  
                          id="link"
                          icon={<BsLink45Deg />}
                          iconColor={errors.link ? "danger.100" : "primary.500"}
                          touched={touched.link}
                          errors={errors.link}                                                      
                        />
                      </Box>
                    </Flex>
                  </Box>
                  <Box flex="1" mt="0.7rem">
                    <Flex
                      alignItems="center"
                      style={{ gap: "1.5rem" }}
                    >
                      <Box>
                        <InputSelect                      
                          name="tag"
                          id="tag"
                          placeholder="Tag*"
                          iconColor={errors.tag ? "danger.100" : "primary.500"}
                          touched={touched.tag}
                          errors={errors.tag}
                          options={[
                            { value: "IT", label: "IT" },
                            { value: "AV", label: "AV" },
                          ]}
                          formik
                          value={values.tag}
                          setValue={setFieldValue}
                          fieldName="tag"
                        />
                      </Box>
                      <Box>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          bg="secondary.100"
                          htmlFor="image"                          
                          width="fit-content"
                          borderRadius="0.6rem" 
                          color="white"  
                          height="4rem"  
                          px="2rem"   
                          as="label"
                          cursor="pointer"                  
                        >
                          Add Images                          
                        </Flex>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          multiple
                          onChange={handleImage}
                          style={{ display: "none" }}                          
                        />
                      </Box>
                    </Flex>
                    <Flex
                      flexWrap="wrap"
                      style={{ gap: "1rem" }}
                      mt="1.5rem"
                    >
                      {images?.map((image, index) => (
                        <Box>
                          <Image
                            src={URL.createObjectURL(image)}
                            alt={index}
                            width="10rem"
                            height="10rem"
                            borderRadius="0.4rem"
                            objectFit="cover"
                          />
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                </Flex>            
                <Button
                  variant="primary"                  
                  px="2rem"
                  py="1rem"
                  type="submit"                  
                  mt="3rem"
                  fontSize="1.6rem"
                  borderRadius="2rem"
                  loading={loading}
                >                  
                  Create                 
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}