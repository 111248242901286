import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { MdVisibility } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Box, Flex, Image, InputSelect, Loader, Text } from "atoms";
import { getReviews, updateReview } from "redux/actions";
import { reviewConstants } from "redux/constants";

export const ReviewPage = () => {

  const dispatch = useDispatch();
  const { reviews, totalCount, resultPerPage, loading, error, message } = useSelector(state => state.review);

  const [ page, setPage ] = useState(1);
  const [ rating, setRating ] = useState(null);

  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type: reviewConstants.RESET_REVIEW})
    }
    if(message){
      toast.success(message)
      dispatch({type: reviewConstants.RESET_REVIEW})
    }
  }, [error, message]);

  useEffect(() => {    
    dispatch(getReviews({ page, rating }))
  }, [dispatch, page, rating])

  useEffect(() => {
    setPage(1)
  }, [rating])

  const handleUpadate = (id, active) => {
    dispatch(updateReview(id, { active: !active }))
  }

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            fontSize="2rem"
            color={params.row.active ? "success.100" : "danger.100"}
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            onClick={() => handleUpadate(params.row.id, params.row.active)}
          >
            <MdVisibility />            
          </Flex>
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "name", headerName: "Name", minWidth: 200, flex: 1,
      renderCell: (params) => (  
        <Flex
          alignItems="center"
          style={{
            gap: "0.5rem"
          }}
        >
          <Box
            width="4rem"
            height="4rem"
          >
            <Image
              src={params.row.image}
              alt={params.row.name}
              width="100%"
              height="100%"
              objectFit="cover"
              borderRadius="0.5rem"
            />
          </Box>
          <Text
            fontSize="1.4rem"
            fontWeight="500"
            color="grey.500"
          >
            {params.row.name}
          </Text>      
        </Flex>                                 
      )
    },
    { field: "designation", headerName: "Designation", minWidth: 150, flex: 1 },
    { field: "rating", headerName: "Rating", minWidth: 100, flex: 1 },
    { field: "comment", headerName: "Comment", minWidth: 300, flex: 1 },        
  ]

  const rows = [];
  reviews?.forEach(r => {    
    rows.push({
      id: r.id,
      name: r.name,
      image: r.image,
      designation: r.designation,
      rating: r.rating,
      comment: r.comment,
      active: r.active,      
    });
  });

  return (
    <Box>
      {loading && <Loader/>}
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Reviews
        </Text>
        <Box>
          <InputSelect
            label="Rating"
            value={rating}
            setValue={setRating}
            placeholder="Select Rating"
            options={[              
              { value: 5, label: "5" },
              { value: 4, label: "4" },
              { value: 3, label: "3" },
              { value: 2, label: "2" },
              { value: 1, label: "1" },
            ]}
            normal
            name="rating"
            id="rating"
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box>   
      {resultPerPage < totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={resultPerPage}
          totalItemsCount={totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}            
      {totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {totalCount}
      </Text>}
    </Box>
  )
}