import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLogout, AiOutlineSetting, AiOutlineTool } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Avatar } from "@material-ui/core";

import { Box, Flex, Image, Text } from "atoms";
import { HeaderOption } from "molecules";
import { logout } from "redux/actions";

export const Header = () => {

  const [ avatarHover, setAvatarHover ] = useState(false)  

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const navigate = useNavigate();

  const headerIcons = [
    {
      Icon: <IoMdNotificationsOutline />,
      text: "Notifications",      
      badge: 2,
      onClick: () => console.log("Notifications")
    },
    {
      Icon: <AiOutlineTool />,
      text: "Tools",
      onClick: () => navigate("/tools")
    },
    {
      Icon: <AiOutlineSetting/>,
      text: "Settings",
      onClick: () => console.log("Settings")
    }
  ]

  const avatarDropdown = [    
    {
      "name": user?.name || user?.email,
      "Icon": <HiOutlineUserCircle />,
      "onClick": () => navigate("/profile")
    },
    {
      "name": "Logout",
      "Icon": <AiOutlineLogout />,
      "onClick": () => dispatch(logout())
    },
  ]

  return (
    <Box
      width="100%"
      height="5rem"      
      position="sticky"
      top="0"
      zIndex="99"
      bg="white"    
      boxShadow="0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)"
    >      
      <Flex
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        pr="2rem"
      >
        {/* APP LOGO */}
        <Link to="/dashboard">        
          <Image
            src="/favicon-main.png"
            alt="logo"
            width="4rem"            
            left="1.5rem"
          />
        </Link>
        <Flex
          alignItems="center"          
        >
          <Flex
            alignItems="center"
            mr="1rem"
            justifyContent="center"
            border="2px solid"
            borderColor="success.100"
            p="0.5rem 1rem"
            borderRadius="2rem"
            cursor="pointer"
          >
            <Box
              width="0.8rem"
              height="0.8rem"
              borderRadius="50%"
              bg="success.200"
              mr="0.5rem"              
            />
            <Text
              fontSize="1.4rem"
              fontWeight="600"
              textTransform="uppercase"
              color="success.200"
            >
              ADMIN CMS
            </Text>
          </Flex>
          {/* HEADER ICONS */}
          {
            headerIcons.map((item, index) => (
              <HeaderOption
                key={index}
                Icon={item.Icon}
                text={item.text}
                badge={item.badge}
                onClick={item.onClick}
              />
            ))
          }
          {/* AVATAR */}   
          <Box
            width="3rem"
            height="3rem"
            pt="0.1rem"
            ml="1rem"
            cursor="pointer"
            onMouseEnter={() => setAvatarHover(true)}
            onMouseLeave={() => setAvatarHover(false)}                                   
          >
            <Avatar
              style={{
                width: "3rem",
                height: "3rem"                
              }}
              src={user?.image ? user?.image : ""}
            />
            {/* AVATAR DROPDOWN */}
            {
              avatarHover &&  <Box
                position="absolute"
                top="120%"
                right="-1rem"                
                bg="white"              
                color="grey.300"
                borderRadius="0.2rem"
                p="0.5rem 2rem"                
                boxShadow="0 0 0.5rem rgba(0, 0, 0, 0.2)"
                transition="all 0.4s ease-in-out"
                zIndex="199"  
                minWidth="22rem"  
                maxWidth="25rem"              
              >              
                <Box
                  position="absolute"
                  top="-0.7rem"
                  left="82%"                  
                  width="0"
                  height="0"
                  borderLeft="0.7rem solid transparent"
                  borderRight="0.7rem solid transparent"
                  borderBottom="0.7rem solid white"                  
                />
                {
                  avatarDropdown.map((item, index) => (
                    <Flex
                      key={index}
                      fontSize="2rem"
                      alignItems="center"
                      py="1rem"                
                      borderBottom={index + 1 === avatarDropdown.length ? "unset" : "1px solid"}
                      borderColor="whitesmoke"
                      onClick={item.onClick}
                    >                
                      {item.Icon}
                      <Text
                        ml="0.5rem"
                        mt="-0.15rem"                  
                      >
                        {item.name}
                      </Text>
                    </Flex>                            
                  ))
                }              
              </Box>
            }
          </Box>   
        </Flex>
      </Flex>
    </Box>
  )
}