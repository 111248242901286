import { reviewConstants } from "redux/constants"

const initState = {  
  loading: false,
  error: null,  
  message: null,
  reviews: [],
  totalCount: 0,
  resultPerPage: 0
}

export const reviewReducer = (state = initState, action) => {
  switch (action.type) {
    case reviewConstants.GET_REVIEWS_REQUEST:
    case reviewConstants.CREATE_REVIEW_REQUEST:
    case reviewConstants.UPDATE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case reviewConstants.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload.reviews,
        totalCount: action.payload.totalCount,
        resultPerPage: action.payload.resultPerPage,
        loading: false,
      }

    case reviewConstants.CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [action.payload.data, ...state.reviews],
        message: action.payload.message,
        loading: false,
      }

    case reviewConstants.UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: state.reviews.map(review => review.id === action.payload.data.id ? action.payload.data : review),
        message: action.payload.message,
        loading: false,
      }

    case reviewConstants.GET_REVIEWS_FAILURE:
    case reviewConstants.CREATE_REVIEW_FAILURE:
    case reviewConstants.UPDATE_REVIEW_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case reviewConstants.RESET_REVIEW:
      return{
        ...state,
        error: null,
        message: null,
        loading: false,        
      } 
      
    case reviewConstants.RESET:
      return initState    

    default: return state;
  }
}