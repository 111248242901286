import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, Loader, Text } from "atoms";
import { forgotPassword, login } from "redux/actions";
import { userConstants } from "redux/constants";
import { getAssetURL } from "utils/utilities";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  password: Yup.string()    
    .required("Password Required")
    .min(6, "Min 6 characters required"),    
});

export const LoginPage = () => {

  const dispatch = useDispatch();
  const { error, isForgotPassword, loading, message } = useSelector(state => state.user);

  useEffect(() => {
    if(error){
      toast.error(error);
      dispatch({ type: userConstants.RESET_USER });
    }
    if(isForgotPassword){
      toast.success(message);
      dispatch({ type: userConstants.RESET_USER });
    }    
  }, [loading, error, isForgotPassword, message, dispatch]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {      
      email: values.email,
      password: values.password,      
    }     
    dispatch(login(form.email, form.password));    
    values.password = "";
    setSubmitting(false);          
  }

  const handleForgotPassword = (email) => {
    if(email === ""){
      toast.error("Please enter your email address");
      return;
    }
    dispatch(forgotPassword(email));
  }

  return (
    <Flex
      width="100%"
      height="100vh"
    >     
      {loading && <Loader/>} 
      <Box
        flex={{xs: "1", xm: "2"}}        
        display={{ xs: "none", xm: "block"}}
        backgroundImage={`url(${getAssetURL("login-bg.jpg")})`}
        backgroundSize="cover"
      />
      <Flex
        flex="1"        
        flexDirection="column"
        alignItems="center"
        p="2rem"
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
        >          
          <Image
            src={getAssetURL("logo.png")}
            alt="logo"
            width="20rem"
            objectFit="contain"
          />
          <Box my="1rem" width={{xs: "80%", md: "60%", xm: "80%"}}>
            <Formik
              initialValues={{              
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, values }) => {
                return (                  
                  <Form>                  
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email Address*"
                      id="email"
                      icon={<AiOutlineMail />}
                      iconColor={errors.email ? "danger.100" : "secondary.300"}
                      touched={touched.email}
                      errors={errors.email}                            
                    />
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password*"
                      id="password"
                      icon={<AiOutlineLock />}
                      iconColor={errors.password ? "danger.500" : "secondary.300"}
                      touched={touched.password}
                      errors={errors.password}
                    />   

                    <Flex
                      justifyContent="flex-end"                    
                    >
                      <Text
                        fontSize="1.4rem"
                        mt="1rem"
                        color="bPrimary.100"
                        cursor="pointer"
                        fontWeight="500"
                        onClick={() => handleForgotPassword(values.email)}
                      >
                        Forgot Password?
                      </Text>
                    </Flex>

                    <Button
                      variant="secondary"                    
                      px="2rem"
                      py="1rem"
                      type="submit"
                      ml="auto"
                      mt="1.5rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                    >
                      <Text fontSize="1.6rem" fontWeight="600">
                        Login
                      </Text>
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Flex>
        <Box my="1rem">          
          <Text textAlign="center">
            Developed By
            {" "}<span>
              🚀
            </span>{" "}
            <a href="https://pgkonnect.com">PGKPL</a>
          </Text>
          <Text textAlign="center">
            Copyright &copy; 2023
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}