import { ErrorMessage, Field } from "formik";

import { Error } from "../Error";
import Box from "../Box";
import Text from "../Text";

export const InputSelect = ({
  value,
  setValue,
  options,
  label,
  placeholder,
  formik,
  fieldName,
  touched,
  errors,
  name, 
  id,
  lessMB,
  noDefault,
  disabled,
  onChange,
  normal
}) => {
  return (
    <Box      
      width="100%" 
      mt="0.3rem"     
    >                 
      <Text
        as="label"  
        htmlFor={id}
        fontSize="1.2rem"
        fontWeight="300"
        color="grey.300"  
        mt="0.4rem"
      >
        {label}
      </Text> 
      {normal ?
        <Box width="100%">                  
          <select
            id={id}
            name={name}
            mb={lessMB ? "0.8rem" : "2rem"} 
            mt={label ? "-0.8rem" : "unset"}          
            disabled={disabled}                           
            className={"form-input-select"}                    
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{
              width: "100%"
            }}
          >
            {!noDefault && <option value="">{placeholder}</option>}
            {options?.map((item, index) => (
              <option               
                key={index} 
                value={item.value}
                style={{
                  textTransform: "capitalize"
                }}
              >
                {item.label}
              </option>
            ))}
          </select> 
        </Box>
      : <>
        <Field
          mb={lessMB ? "0.8rem" : "2rem"} 
          mt={label ? "-0.8rem" : "unset"}
          as="select" 
          disabled={disabled}       
          id={id}
          name={name}
          style={{
            width: "100%",
            textTransform: "capitalize",
            borderColor: touched && !errors ? "#0BBA7A" : ""
          }}
          className={touched && errors ? "input-error form-input-select" : "form-input-select"}                    
          value={value}
          onChange={onChange ? onChange : (e) => {
            if(formik){
              setValue(fieldName, e.target.value)
            }else{
              setValue(e.target.value)
            }
          }}          
        >
          {!noDefault && <Field as="option" value="">{placeholder}</Field>}
          {options?.map((item, index) => (
            <Field
              as="option"  
              key={index} 
              value={item.value}
              style={{
                textTransform: "capitalize"
              }}
            >
              {item.label}
            </Field>
          ))}
        </Field>
        <ErrorMessage name={name} component={Error} />             
      </>}      
    </Box>  
  )
}