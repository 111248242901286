import { projectConstants } from "redux/constants";
import Axios from "utils/axios";

export const getProjects = (filter) => {
  return async (dispatch) => {
    try{
      dispatch({ type: projectConstants.GET_PROJECTS_REQUEST })
      let URL = `/admin/projects?page=${filter.page}`;   
      if(filter.tag) URL += `&tag=${filter.tag}`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: projectConstants.GET_PROJECTS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: projectConstants.GET_PROJECTS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createProject = (body) => {
  return async (dispatch) => {
    try{
      dispatch({ type: projectConstants.CREATE_PROJECT_REQUEST })
      const { data } = await Axios.post(`/projects`, body);                  
      dispatch({ 
        type: projectConstants.CREATE_PROJECT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: projectConstants.CREATE_PROJECT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateProject = (id, body) => {
  return async (dispatch) => {
    try{
      dispatch({ type: projectConstants.UPDATE_PROJECT_REQUEST })
      const { data } = await Axios.put(`/projects/${id}`, body);                  
      dispatch({ 
        type: projectConstants.UPDATE_PROJECT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message || "Project updated successfully"
        }
      })
    }catch(error){           
      dispatch({
        type: projectConstants.UPDATE_PROJECT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}