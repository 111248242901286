import { useState } from "react";
import { BsFillImageFill } from "react-icons/bs";
import { FaLanguage } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { toast } from "react-toastify";

import { Box, Button, Flex, Text } from "atoms";
import slugify from "slugify";

const data = [
  {    
    "title": "Covert Image to PDF",
    "url": "https://www.ilovepdf.com/jpg_to_pdf",
    "description": "Convert Image to PDF Online for Free",
    "icons": [
      <BsFillImageFill/>,
      <GrDocumentPdf/>
    ]
  },
  {
    "title": "Convert PDF to Image",
    "url": "https://www.ilovepdf.com/pdf_to_jpg",
    "description": "Convert PDF to JPG Online for Free",
    "icons": [
      <GrDocumentPdf/>,
      <BsFillImageFill/>
    ]
  },
  {
    "title": "Compress Image",
    "url": "https://www.iloveimg.com/compress-image",
    "description": "Compress Image Online for Free",
    "icons": [      
      <BsFillImageFill/>
    ]
  },
  {
    "title": "Convert Image Format",
    "url": "https://www.onlineimagetool.com/en/convert-png-jpg-webp-gif",
    "description": "Convert Image Format Online for Free",
    "icons": [
      <BsFillImageFill/>
    ]
  },
  {
    "title": "Hindi Font To Unicode",
    "url": "http://updes.up.nic.in/esd/font_converter/index.html",
    "description": "Convert Hindi Font to Unicode Online for Free",
    "icons": [
      <FaLanguage/>
    ]
  }
]

export const ToolsPage = () => {

  const [ slug, setSlug ] = useState("")

  const getUniqueName = () => {
    let name = new Date().toISOString().replace(/[-:.]/g,"");    
    navigator.clipboard.writeText(name);
    toast.success("Unique Name Copied to Clipboard");
  }

  const doSlug = () => {
    let _slug = slugify(slug, {lower: true, strict: true});
    navigator.clipboard.writeText(_slug);
    toast.success("Slug Copied to Clipboard");
  }

  const pingAPI = () => {
    fetch("https://ssju.ac.in/apis/api/server-info")    
    .then(res => {    
      console.log(res);  
      toast.success("API Pinged Successfully");
    })
    .catch(err => {    
      console.log(err);      
      toast.error("API Ping Failed");
    })
  }

  return (
    <>
      <Flex
        width="100%"        
        p="2rem"
        flexWrap="wrap"                      
      > 
        {data.map((item, index) => (
          <Box 
            key={index}            
            bg="white"
            width="fit-content"
            borderRadius="0.5rem"
            boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
            mr="2rem"
            mb="2rem"
          >
            <a href={item.url} target="_blank" rel="noreferrer">
              <Box                
                p="2rem"
              >
                <Flex mb="2rem" alignItems="center" justifyContent="center">
                  {item.icons.map((icon, index) => (
                    <Box key={index} fontSize="2rem" mx="1rem">
                      {icon}
                    </Box>
                  ))}
                </Flex>
                <Text fontSize="1.5rem" fontWeight="bold" color="black">{item.title}</Text>
                <Text fontSize="1rem" color="black" as="p">{item.description}</Text>
              </Box>
            </a>
          </Box>
        ))}
      </Flex>

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        id="form"
        my="2rem"
      >  
        <Flex
          width="100%"          
          flexDirection="column"
        >
          <Flex alignItems="center">
            <Box>
              <Button
                variant="danger"
                width="fit-content"
                p="1rem 2rem"
                borderRadius="0.5rem" 
                onClick={getUniqueName}         
              >
                Get Unique Name
              </Button>      
            </Box>
            <Box>
              <Button
                variant="success"
                width="fit-content"
                p="1rem 2rem"
                borderRadius="0.5rem" 
                onClick={pingAPI} 
                ml="1rem"        
              >
                Ping APIS
              </Button>      
            </Box>
          </Flex>
          <Flex mt="2rem">
            <input
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              placeholder="Enter Value to Slug"
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                border: "1px solid #ccc",
                minWidth: "25rem"
              }}              
            />
            <Button
              variant="warning"
              width="fit-content"
              p="1rem 2rem"
              borderRadius="0.5rem" 
              onClick={doSlug}   
              ml="1rem"      
            >
              Slugify
            </Button>    
          </Flex>
        </Flex>
      </Box>
    </>
  )
}