import React, { useEffect, useState } from "react";

import { Box, Flex, Text } from "atoms";

export const Footer = () => {

  const [date, setDate] = useState(new Date());
  
  useEffect(() => {    
    const timer = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      minHeight="3rem"
      maxHeight="3rem"      
      boxShadow="0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
      bg="primary.500"
      color="white"
    >
      <Flex 
        p="0.5rem 2rem"        
        alignItems="center"
        justifyContent="space-between"              
      >      
        <Text
          fontSize="1.4rem"
          fontWeight="400"
        >
          {new Date(date).toLocaleString()}
        </Text>         
        <Text
          fontSize="1.4rem"
          fontWeight="400"          
        >
          © 2023 - All Rights Reserved
        </Text>
        <Text
          fontSize="1.4rem"
          fontWeight="400"
        >
          PGKONNECT
        </Text>        
      </Flex>
    </Box>
  )
}