export const appConstants = {  
}

export const userConstants = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",  
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",  
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  RESET_USER: "RESET_USER",
  RESET: "RESET"
}

export const reviewConstants = {
  GET_REVIEWS_REQUEST: "GET_REVIEWS_REQUEST",
  GET_REVIEWS_SUCCESS: "GET_REVIEWS_SUCCESS",
  GET_REVIEWS_FAILURE: "GET_REVIEWS_FAILURE",
  CREATE_REVIEW_REQUEST: "CREATE_REVIEW_REQUEST",
  CREATE_REVIEW_SUCCESS: "CREATE_REVIEW_SUCCESS",
  CREATE_REVIEW_FAILURE: "CREATE_REVIEW_FAILURE",
  UPDATE_REVIEW_REQUEST: "UPDATE_REVIEW_REQUEST",
  UPDATE_REVIEW_SUCCESS: "UPDATE_REVIEW_SUCCESS",
  UPDATE_REVIEW_FAILURE: "UPDATE_REVIEW_FAILURE",
  RESET_REVIEW: "RESET_REVIEW",
  RESET: "RESET"
}

export const projectConstants = {
  GET_PROJECTS_REQUEST: "GET_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE: "GET_PROJECTS_FAILURE",
  CREATE_PROJECT_REQUEST: "CREATE_PROJECT_REQUEST",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE: "CREATE_PROJECT_FAILURE",
  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",
  RESET_PROJECT: "RESET_PROJECT",
  RESET: "RESET"
}