import { reviewConstants } from "redux/constants";
import Axios from "utils/axios";

export const getReviews = (filter) => {
  return async (dispatch) => {
    try{
      dispatch({ type: reviewConstants.GET_REVIEWS_REQUEST })
      let URL = `/admin/reviews?page=${filter.page}`;   
      if(filter.rating) URL += `&rating=${filter.rating}`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: reviewConstants.GET_REVIEWS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: reviewConstants.GET_REVIEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateReview = (id, body) => {
  return async (dispatch) => {
    try{
      dispatch({ type: reviewConstants.UPDATE_REVIEW_REQUEST })
      const { data } = await Axios.put(`/reviews/${id}`, body);                  
      dispatch({ 
        type: reviewConstants.UPDATE_REVIEW_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: reviewConstants.UPDATE_REVIEW_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}