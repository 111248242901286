import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { AiOutlineArrowUp } from "react-icons/ai";

import { Box, Loader } from "atoms";
import { Layout } from "organisms";
import Login from "pages/login";
import { loadUser } from "redux/actions";
import { appConstants } from "redux/constants";
import { AppRoutes } from "routes";
import { theme } from "styles";
import { scrollToTop } from "utils/utilities";

import "react-toastify/dist/ReactToastify.css";
import "styles/globals.css";
import "styles/style.css";

function App() {      

  const [scroll, setScroll] = useState(false);

  const dispatch = useDispatch();
  const { message, error  } = useSelector((state) => state.app);
  const { isAuthenticated, userLoading  } = useSelector((state) => state.user);

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: appConstants.RESET_APP });
    }
    if(error){
      toast.error(error);
      dispatch({ type: appConstants.RESET_APP });
    }
  }, [message, error, dispatch]);

  useEffect(() => {    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (    
    <ThemeProvider theme={theme}>  
      <ToastContainer         
        style={{ fontSize: "1.4rem" }}  
        position="top-right"      
      />
      {userLoading === true ? <Loader withBg />
      : isAuthenticated === false ? 
        <Login/>
      : isAuthenticated === true && <Router>
        <Layout>
          <AppRoutes />
        </Layout>
      </Router>}  
      {scroll && <Box
        position="fixed"
        color="primary.100"
        bg="primary.200"
        bottom="4rem"
        right="2rem"
        width="3rem"
        height="3rem"
        borderRadius="0.2rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => scrollToTop(window)}
      >
        <AiOutlineArrowUp size="2rem" />
      </Box>}   
    </ThemeProvider>   
  );
}

export default App;